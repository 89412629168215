import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { c } from 'common/common';

export class QuickLinksButton {
    static inject = [EventAggregator, Security];
    _ea;
    security;

    constructor(ea, security) {
        this._ea = ea;
        this.security = security;
    }
    
    openNbr() {
        const nbrLink = c.Helpers.replaceVariables(this.security, `https://form.jotform.com/210443910285046?agentName[first]={{firstName}}&agentName[last]={{lastName}}&agentEmail={{email}}&agentMobile[full]={{cell}}&baseshopName[first]={{agencyFirstName}}&baseshopName[last]={{agencyLastName}}&baseshopEmail={{agencyEmail}}&baseshopMobile[full]={{agencyCell}}&agentNumber={{agentID}}`, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: nbrLink });
        this._closeMenu();
    }

    shareMyLink() {
        this.security.openShareLink();
        this._closeMenu();
    }

    _closeMenu() {
        this.closeMenu = new Date().getTime();
        return true;
    }
}