import { bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Tags as TagService } from 'services/tags';
import { distinctArray } from 'common/common';
import Tagify from '@yaireo/tagify';

export class Tags {
    static inject = [Element, EventAggregator, TagService];
    _element;
    _ea;
    _tags;

    _tagify;
    tagsEl;
    @observable tagList;

    @bindable tagOptions;

    @bindable type;
    @bindable referenceId;
    @bindable tags;
    @bindable maxItems = 10;
    @bindable onlyAllowTagOptions = false;

    _handlers = [];

    constructor(element, ea, tags) {
        this._element = element;
        this._ea = ea;
        this._tags = tags;
    }

    attached() {
        this._handlers.push(this._ea.subscribe('lpfn.tags.updated', (data) => this._updateWhitelist(data.type, data.tags)));
        this._initialize();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    tagOptionsChanged(newValue, oldValue) {
        if (!newValue && !oldValue) return;
        this._initialize();
    }

    async _initialize() {
        if (!this.tagsEl) return;
        try {
            if (!this.type) {
                if (!this.tags) this.tags = [];
                this.tagList = this.tags.join(',');
                this.availableTags = this.tagOptions ?? [];

                if (this._tagify) {
                    this._updateWhitelist(null, this.availableTags);
                    return;
                }
            } else {
                if (!this.tags && this.referenceId) this.tags = await this._tags.list(this.type, this.referenceId);
                if (this.tags) this.tagList = this.tags.join(',');
                this.availableTags = await this._tags.list(this.type);
            }
            this._tagify = new Tagify(this.tagsEl, {
                whitelist: this.availableTags,
                enforceWhitelist: this.onlyAllowTagOptions,
                dropdown: {
                    enabled: 0,
                    maxItems: this.maxItems,
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    _updateWhitelist(tagType, tags) {
        if (!this._tagify) return;
        if (this.type && tagType !== this.type) return;
        const existing = this._tagify.whitelist;
        const newWhitelist = distinctArray([...existing, ...tags]);
        this._tagify.whitelist = newWhitelist;
    }

    tagsChanged() {
        if (this.tags) this.tagList = this.tags.join(',');
    }

    async tagListChanged() {
        if (!this._tagListInitialized) { this._tagListInitialized = true; return; }
        try {
            const tags = this.tagList ? JSON.parse(this.tagList).map(x => x.value) : [];
            if (!this.type) {
                // for tag options, just publish an event
                this._element.dispatchEvent(new CustomEvent('updated', { bubbles: true, detail: tags }));
            } else {
                // When these are tag options, do not assign
                await this._tags.assign(this.type, this.referenceId, tags);
                this._ea.publish('lpfn.tags.updated', { type: this.type, tags: tags });
            }
        } catch (err) {
            console.log(err);
        }
    }
}