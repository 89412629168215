import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator'
import { Api } from 'common/server';
import { Security } from 'common/security';
import { LpfnUtil } from 'common/utils';
import { eventListener } from 'common/common';
import { c } from 'common/common';

export class Search {
    static inject = [Element, Router, EventAggregator, Api, Security];
    element;
    _router;
    _ea;
    _api;
    _security;

    RECENT_LS_KEY = 'lpfn-search-recent';
    MAX_RECENT_RESULTS = 25;

    menuTriggerEventKey = 'lpfn-search-menu';

    _defaultOptions = {
        minLength: 2,
        keypress: true,
        enter: true,
        layout: 'menu',
        responsive: null,
        showOnFocus: true,
    };

    options = {};

    searchOn = '';

    _handlers = [];

    constructor(element, router, ea, api, security) {
        this.element = element;
        this._router = router;
        this._ea = ea;
        this._api = api;
        this.security = security;
    }

    attached() {
        this._init();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    openPolicy(policy) {
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policy.id });
    }

    openAgent(agent) {
		this._ea.publish(c.EventKeys.site.openProfile, { memberId: agent.id });
    }

    _init() {
        this.RECENT_LS_KEY = `lpfn-search-recent-${this.security.authenticatedMemberId}`;
        this.options = LpfnUtil.deepExtend({}, this._defaultOptions, this.options);
        this.processing = false;

        this.element.setAttribute('data-kt-search', 'true');
        this.layout = this._getOption('layout');
        
        this._update();

        this._loadRecentResults();

        this._handlers.push(eventListener(window, 'resize', (e) => {
            let timer;
            LpfnUtil.throttle(timer, () => { this._update(); }, 200);
        }));
    }

    focus() {
        this.inputEl.removeAttribute('readonly');
        this.element.classList.add('focus');
        if (this._getOption('show-on-focus') === true || this.searchOn.length >= minLength) {
            this.show();
        }        
    }

    blur() {        
        this.element.classList.remove('focus');
    }

    keypress(e) {
        // suppress the enter key so it doesn't submit the form and reload the page
        var key = e.charCode || e.keyCode || 0;
        if (key === 13) {
            return false;
        }
        return true;
    }

    keyup(e) {
        this._typing = true;
        const minLengthOption = this._getOption('min-length');
        var key = e.charCode || e.keyCode || 0;
        if (key == 13) {
            e.preventDefault();
            e.stopPropagation();
            this.search();
            return false;
        }
        if (!minLengthOption) {
            return false;
        }

        const minLength = parseInt(minLengthOption);

        if (this.searchOn.length >= minLength ) {
            this.search();
        } else if (this.searchOn.length === 0) {
            this.clear();
        }
        return true;
    }

    menuClosed() {
        this.hide();
    }

    async search() {
        if (this.processing) return;
        this.searchComplete = false;
        this.showSearchResults = false;

        // Focus input
        this.inputEl.focus();

        this.processing = true;

        try {
            if (!this.searchOn) return;
            this.searching = true;
            const results = await this._api.get(`search?term=${encodeURIComponent(this.searchOn)}`);
            this.policies = results.filter(x => x.resultType === 'policy');
            let i = 0;
            this.policies.forEach(x => x.show = i++ < 3);
            this.agents = results.filter(x => x.resultType === 'agent');
            i = 0;
            this.agents.forEach(x => x.show = i++ < 3);
            this.carriers = results.filter(x => x.resultType === 'carrier');
            i = 0;
            this.carriers.forEach(x => x.show = i++ < 3);
            this.products = results.filter(x => x.resultType === 'product');
            i = 0;
            this.products.forEach(x => x.show = i++ < 3);
            this.webPages = results.filter(x => x.resultType === 'web-page');
            i = 0;
            this.webPages.forEach(x => x.show = i++ < 3);
            this.files = results.filter(x => x.resultType === 'file');
            i = 0;
            this.files.forEach(x => x.show = i++ < 3);
            this._saveRecentResults(results);

            this.showSearchResults = this.policies.length || this.agents.length || this.carriers.length || this.products.length || this.webPages.length || this.files.length;
            this.showEmptySearchResults = !this.showSearchResults;
            this.complete();
        } catch (err) {
            console.log(err);
            this.processing = false;
            this.showRecentSearches = true;
            this.searchComplete = true;
        } finally {
            this.searching = false;
        }
    }

    openRecentResult(result) {
        switch (result.resultType) {
            case 'policy':
                this.openPolicy(result);
                break;
            case 'agent':
                this.openAgent(result);
                break;
            case 'carrier':
                this.hide();
                this._router.navigate(`#/members/carriers/${result.metadata.slug}`, true);
                break;
            case 'product':
                this.hide();
                this._router.navigate(`#/members/carriers/${result.metadata.carrierSlug}`, true);
                break;
            case 'web-page':
                this.hide();
                this._router.navigate(result.metadata.url);
                break;
            case 'file':
                this.hide();
                this.openFile(result);
                break;
        }
    }

    openFile(file) {
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: file.metadata.url });
    }

    _loadRecentResults() {
        try {
            const recentResultsValue = localStorage.getItem(this.RECENT_LS_KEY);
            this.recentResults = recentResultsValue ? JSON.parse(recentResultsValue) : [];
        } catch (err) {
            console.log(err);
        }
    }

    _saveRecentResults(results) {
        try {
            results.forEach(r => this.recentResults.unshift(r));
            if (this.recentResults.length > this.MAX_RECENT_RESULTS) {
                this.recentResults.splice(this.MAX_RECENT_RESULTS);
            }
            const filteredRecentResults = [];
            this.recentResults.forEach(rr => {
                const exists = filteredRecentResults.find(x => x.resultType === rr.resultType && x.id === rr.id);
                if (exists) return;
                filteredRecentResults.push(rr);
            });
            this.recentResults = filteredRecentResults;
            localStorage.setItem(this.RECENT_LS_KEY, JSON.stringify(this.recentResults));
        } catch (err) {
            console.log(err);
        }
    }

    clearRecentResults() {
        this.recentResults = [];
        localStorage.setItem(this.RECENT_LS_KEY, JSON.stringify(this.recentResults));
    }

    complete() {
        if (this.searchOn.length === 0) {
            this.clear();
        }

        // Focus input
        this.inputEl.focus();

        this.show();

        this.processing = false;
        this.showRecentSearches = false;
        this.searchComplete = true;
    }

    clear() {
        // Clear and focus input
        this.searchOn = '';
        this.inputEl.focus();
        this.searchComplete = false;
        this.showSearch = true;
        this.showRecentSearches = true;
        this.showSearchResults = false;
        this.showEmptySearchResults = false;
        this.showingSearch = false;

        if (this._getOption('show-on-focus') === false ) {
            this.hide();
        }
    }

    _update() {
        // Handle responsive form
        var responsiveFormMode = this._getResponsiveFormMode();

        if (responsiveFormMode === 'on' && this.contentEl.contains(this.formEl) === false) {
            this.contentEl.prepend(this.formEl);
            this.formEl.classList.remove('d-none');                
        } else if (responsiveFormMode === 'off' && this.contentEl.contains(this.formEl) === true) {
            this.element.prepend(this.formEl);
            this.formEl.classList.add('d-none');
        }
    }

    toggle() {
        if (!this.showingSearch) {
            this.showSearchInResults = true;
            this.show();
        } else {
            this.showSearchInResults = false;
            this.hide();
        }
    }

    show() {
        this._ea.publish(this.menuTriggerEventKey, { action: 'show' });
        this.showSearch = true;
        this.showRecentSearches = true;
        this.showingSearch = true;
        this._update();
    }

    hide() {
        this._ea.publish(this.menuTriggerEventKey, { action: 'hide' });
        this.showSearch = true;
        this.showingSearch = false;
        this.searchComplete = false;
        this.showRecentSearches = true;
        this.showSearchResults = false;
        this.showEmptySearchResults = false;
        this.showAdvancedSearchOptions = false;
        this.showSearchInResults = false;
        this._update();
        return true;
    }

    showAdvancedSearch() {
        this.showSearch = false;
        this.showAdvancedSearchOptions = true;
    }

    cancelAdvancedSearch() {
        this.showSearch = true;
        this.showAdvancedSearchOptions = false;
    }

    showSearchPreferences() {
        this.showSearch = false;
        this.showSearchPreferencesPanel = true;
    }

    saveSearchPreferences() {
        console.log('save search preferences');
    }

    closeSearchPreferences() {
        this.showSearchPreferencesPanel = false;
        this.showSearch = true;
    }

    _getOption(name) {
        if (this.element.hasAttribute('data-kt-search-' + name) === true) {
            var attr = this.element.getAttribute('data-kt-search-' + name);
            var value = LpfnUtil.getResponsiveValue(attr);

            if (value !== null && String(value) === 'true') {
                value = true;
            } else if (value !== null && String(value) === 'false') {
                value = false;
            }

            return value;
        } else {
            var optionName = LpfnUtil.snakeToCamel(name);

            if (this.options[optionName]) {
                return LpfnUtil.getResponsiveValue(this.options[optionName]);
            } else {
                return null;
            }
        }
    }

    _getResponsiveFormMode() {
        var responsive = this._getOption('responsive');
        if (!responsive) return null;

        var width = LpfnUtil.getViewPort().width;
        var breakpoint = LpfnUtil.getBreakpoint(responsive);

        if (!breakpoint) {
            breakpoint = parseInt(responsive);
        }

        if (width < breakpoint) {
            return "on";
        } else {
            return "off";
        }
    }

    destroy() {
    }

    showAgent(index) {
        if (index <= 3) return true;
        return this.displayAgents;
    }

    toggleResults(type, event) {
        event.stopPropagation();
        event.preventDefault();
        let i = 0;
        switch (type) {
            case 'agents':
                this.displayAgents = !this.displayAgents;
                this.agents.forEach(x => x.show = this.displayAgents || i++ < 3);
                break;
            case 'web-pages':
                this.displayWebPages = !this.displayWebPages;
                this.webPages.forEach(x => x.show = this.displayWebPages || i++ < 3);
                break;
            case 'files':
                this.displayFiles = !this.displayFiles;
                this.files.forEach(x => x.show = this.displayFiles || i++ < 3);
                break;
            case 'carriers':
                this.displayCarriers = !this.displayCarriers;
                this.carriers.forEach(x => x.show = this.displayCarriers || i++ < 3);
                break;
            case 'products':
                this.displayProducts = !this.displayProducts;
                this.products.forEach(x => x.show = this.displayProducts || i++ < 3);
                break;
            case 'policies':
                this.displayPolicies = !this.displayPolicies;
                this.policies.forEach(x => x.show = this.displayPolicies || i++ < 3);
                break;
        }
        return false;
    }

    onCancelClick() {
        this.hide();
        return true;
    }
}
