import { bindable } from 'aurelia-framework';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';

export class Sparkline {
    static inject = [I18n];
    _i18n;

    @bindable data;
    @bindable size;

    chartEl;
    _chart;

    constructor(i18n) {
        this._i18n = i18n;
    }

    attached() {
        this._loadChart();
    }

    dataChanged() {
        this._loadChart();
    }

    _loadChart() {
        if (!this.data || !this.chartEl) return;
        try {
            const labels = [];
            const sparklineData = [];
            let i = 1;            
            this.data.forEach(d => {
                if (typeof d === 'number') sparklineData.push(d);
                else if (typeof d.value === 'number') sparklineData.push(d.value);
                else sparklineData.push(d);
                labels.push(i++);
            });
            const data = {
                labels,
                datasets: [
                    { 
                        data: sparklineData,
                        borderColor: '#cccccc',
                        borderWidth: 2,
                        fill: false
                    }
                ]
            };
            const config = {
                type: 'line',
                data: data,
                options: {
                    title: false,
                    responsive: true,
                    aspectRatio: 3,
                    elements: {
                        point: { radius: 0 }
                    },
                    scales: {
                        x: {
                            display: false,
                            grid: { display: false }
                        },
                        y: {
                            display: false,
                            grid: { display: false }
                        }
                    },
                    plugins: {
                        legend: { display: false },
                        title: { display: false }
                    }
                },
            };

            if (this._chart) {
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
